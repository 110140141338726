import { createFont, createTamagui } from '@mythical/ui'
import { createMedia } from '@tamagui/react-native-media-driver'
import { shorthands } from '@tamagui/shorthands'

import { animations } from './animations'
import { themes } from './theme'
import { tokens } from './tokens'

const fontConfig = {
  weight: {
    true: 400,
  },
  size: {
    1: 10,
    2: 12,
    3: 14,
    4: 16,
    5: 18,
    6: 20,
    '6.5': 22,
    7: 24,
    8: 30,
    9: 36,
    10: 48,
    11: 60,
    12: 72,
    13: 96,
    14: 128,
    15: 144,
    16: 156,
    17: 192,
    true: 16,
  },
  lineHeight: {
    '1': 12,
    '2': 14,
    '3': 17,
    '4': 19,
    '5': 22,
    '6': 24,
    '6.5': 26,
    '7': 28,
    '8': 36,
    '9': 43,
    '10': 58,
    '11': 72,
    '12': 86,
    '13': 115,
    '14': 153,
    '15': 168,
    '16': 184,
    '17': 224,
    true: 19,
  },
  letterSpacing: {
    true: 0
  },
}

const heading = createFont({
  family: 'Aptly',
  face: {
    '400': {
      normal: 'Aptly',
      italic: 'Aptly',
    },
    '500': {
      normal: 'Aptly-Medium',
      italic: 'Aptly-Medium',
    },
    '600': {
      normal: 'Aptly-Bold',
      italic: 'Aptly-Bold',
    },
  },
  ...fontConfig,
})


const body = createFont({
  family: 'GillSans',
  face: {
    '300': {
      normal: 'GillSans-Light',
      italic: 'GillSans-LightItalic',
    },
    '400': {
      normal: 'GillSans',
      italic: 'GillSans',
    },
    '600': {
      normal: 'GillSans-SemiBold',
      italic: 'GillSans-SemiBold',
    },
    '700': {
      normal: 'GillSans-Bold',
      italic: 'GillSans-Bold',
    },
  },
  ...fontConfig,
})

export const config = createTamagui({
  animations,
  defaultTheme: 'light',
  shouldAddPrefersColorThemes: false,
  themeClassNameOnRoot: true,
  shorthands: {
    ...shorthands,
  },
  fonts: {
    heading,
    body,
  },
  themes,
  tokens,
  media: createMedia({
    xxxs: { maxWidth: 375 }, // iPhone SE
    xxs: { maxWidth: 475 },
    xs: { maxWidth: 660 },
    sm: { maxWidth: 800 },
    md: { maxWidth: 1020 },
    lg: { maxWidth: 1280 },
    xl: { maxWidth: 1420 },
    xxl: { maxWidth: 1600 },
    gtXxxs: { minWidth: 375 + 1 },
    gtXxs: { minWidth: 475 + 1 },
    gtXs: { minWidth: 660 + 1 },
    gtSm: { minWidth: 800 + 1 },
    gtMd: { minWidth: 1020 + 1 },
    gtLg: { minWidth: 1280 + 1 },
    short: { maxHeight: 820 },
    tall: { minHeight: 820 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  }),
})
